export const STATUS_DRAFT = "DRAFT";
export const STATUS_PENDING = "PENDING";
export const STATUS_INVOICED = "INVOICED";
export const STATUS_PAIED = "PAIED";
export const STATUS_DELIVERED = "DELIVERED";
export const STATUS_CANCELED = "CANCELED";

export const TRANSACTIONS_STATUS = [
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_INVOICED,
  STATUS_PAIED,
  STATUS_DELIVERED,
  STATUS_CANCELED,
];