import { i18n } from "./i18n";
const objectDenomination = {
  install: (Vue) => {
    Vue.prototype.$objectDenomination = (object) => {
      if (!object) {
        return "";
      }

      if (
        [
          "products",
          "warehouse-products",
          "supplier-products",
          "warehouses",
        ].includes(object.type)
      ) {
        return `${object.name}`;
      }
      if (object.type === "customers") {
        return `${object.customer_name}`;
      }
      if (object.type === "suppliers") {
        return `${object.company_name}`;
      }
      if (object.type === "users") {
        return `${object.firstname} ${object.lastname}`;
      }
      if (object.type === "contacts") {
        return `${object.title} ${object.firstname} ${object.lastname}`;
      }
      if (["organizations", "resellers"].includes(object.type)) {
        return `${object.name}`;
      }
      if (
        [
          "purchases-orders",
          "purchases-invoices",
          "sales-orders",
          "sales-invoices",
        ].includes(object.type)
      ) {
        return `${object.code}`;
      }
      if (["vehicles"].includes(object.type)) {
        let fuel_type = object.fuel_type ?? null;

        if (fuel_type) {
          fuel_type = i18n.tc(`FUEL_TYPES.${fuel_type?.toUpperCase()}`)
            ? i18n.t(`FUEL_TYPES.${fuel_type?.toUpperCase()}`)
            : fuel_type;
        }

        return `${object.model_year} ${object.make} ${object.model} ${object.series ?? ''} ${
          object.style ?? ""
        } ${object.engine_description ?? ""} ${fuel_type ?? ""}`;
      }
      return "NO DENOMINATION PLEASE UPDATE PLUGIN objectDenomination.js";
    };
  },
};

export default objectDenomination;
