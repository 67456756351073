export default {
    CHARGE_PACKAGES: "Packages de charges",
    CHARGE_PACKAGES_LIST: "Liste des packages",
    ADD_CHARGE_PACKAGE: "Ajouter un package",
    CHARGE_PACKAGE_ADDED: "Package ajouté",
    EDIT_CHARGE_PACKAGE: "Modifier un package",
    CHARGE_PACKAGE_UPDATED: "Package mise à jour",
    DELETE_THIS_CHARGE_PACKAGE: "Supprimer ce package ?",
    CHARGE_PACKAGE_DELETED: "Package supprimé",
    CHARGE_PACKAGE_TYPE: "Catégorie de package",
    CATEGORY: "Catégorie",
    VIEW_CHARGE_PACKAGE: "Package",
    RETURN_TO_FROM: "Retourner au formulaire",
    SAME_CHARGE_PACKAGES:
      'Un ou plusieurs packages ont déjà été enregistrés avec le nom {name}. S\'agit-il du même package ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
    CREATED_AT: 'Créé le',
    EDIT_CHARGES:'Modifier les charges',
    PURCHASE_FEES: "Frais d'achat",
    SELLING_FEES: "Frais de vente"
  };
  