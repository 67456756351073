export const PHONE_TYPE_MOBILE = "MOBILE";
export const PHONE_TYPE_HOME = "HOME";
export const PHONE_TYPE_OFFICE = "OFFICE";
export const PHONE_TYPE_BUSINESS = "BUSINESS";
export const PHONE_TYPE_FAX = "FAX";
export const PHONE_TYPE_PAGER = "PAGER";
export const PHONE_TYPE_OTHER = "OTHER";


export const phoneTypesOptions = {
  [PHONE_TYPE_MOBILE]: "MOBILE",
  [PHONE_TYPE_HOME]: "HOME",
  [PHONE_TYPE_OFFICE]: "OFFICE",
  [PHONE_TYPE_BUSINESS]: "BUSINESS",
  [PHONE_TYPE_FAX]: "FAX",
  [PHONE_TYPE_PAGER]: "PAGER",
  [PHONE_TYPE_OTHER]: "OTHER",
};

export const CON_TYPE_GROS = "CON_TYPE_GROS";
export const CON_TYPE_NEW = "CON_TYPE_NEW";

export const MERCHAND_OPTIONS = [CON_TYPE_GROS, CON_TYPE_NEW]

export const CON_REC_TYPE = "REC_TYPE";
export const CON_OTH_TYPE = "OTHER";

export const eventTypeOptions = {
  [CON_REC_TYPE]: "REC_TYPE",
  [CON_OTH_TYPE]: "OTHER",
};

export const QUERY_ACTIONS_VIEW = "view";
export const QUERY_ACTIONS_EDIT = "edit";
export const QUERY_ACTIONS_ADD = "add";
