import {
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_COLLECTED,
  STATUS_DELIVERED,
  STATUS_CANCELED,
} from "../../constants/collectes";
export default {
  COLLECTES_LIST: "Liste des collectes",
  ADD_COLLECTE: "Ajouter une collecte",
  VIEW_COLLECTE: "Collecte",
  COLLECTE_ADDED: "Collecte ajoutée",
  EDIT_COLLECTE: "Modifier une collecte",
  COLLECTE_UPDATED: "Collecte mise à jour",
  DELETE_THIS_COLLECTE: "Supprimer cette collecte ?",
  COLLECTE_DELETED: "Collecte supprimée",
  STATUS: "Statut",
  COLLECTED_AT: "Date de récupération",
  DELIVERED_AT: "Date de livraison",
  COLLECTED_LOCATION: "Adresse de récupération",
  DELIVERED_LOCATION: "Adresse de livraison",
  CARRIER: "Transporteur",
  EXCERPT: "Déscription",
  CANCEL_COLLECTE: "Annuler",
  CANCEL_COLLECTE_QUESTION: "Annuler la collecte ?",
  VALIDATE_COLLECTE: "Valider",
  VALIDATE_COLLECTE_QUESTION: "Valider la collecte ?",
  COLLECT_COLLECTE: "Collecter",
  COLLECT_COLLECTE_QUESTION: "Collecter la collecte ?",
  DELIVER_COLLECTE: "Livrer",
  DELIVER_COLLECTE_QUESTION: "Livrer la collecte ?",
  COLLECTE_CANCELED: "Collecte annulée",
  COLLECTE_VALIDATED: "Collecte velidée",
  COLLECTE_COLLECTED: "Collecte collectée",
  COLLECTE_DELIVERED: "Collecte livrée",
  ALL_STATUS: "Tous les statuts",
  VIEW_COLELCTE: "Collecte",
  STATUS_DRAFT : "Brouillon",
  STATUS_PENDING : "En traitement",
  STATUS_COLLECTED : "Collecté",
  STATUS_DELIVERED : "Livrée",
  STATUS_CANCELED : "Annulée"
};
