export default {
  INVITATIONS_LIST: "Liste des invitations",
  ADD_INVITATION: "Ajouter une invitation",
  INVITATION_ADDED: "Invitation ajoutée",
  OWNER: "Propriétaire",
  EDIT_INVITATION: "Modifier une unvitation",
  INVITATION_UPDATED: "Invitation mis à Jour",
  DELETE_THIS_INVITATION: "Supprimer cette invitation ?",
  INVITATION_DELETED: "Invitation Supprimée",
  INVITATION_ACCEPTED: "Invitation Acceptée",
  INVITATION_APPROVED: "Invitation Approuvée",
  STATUS_APPROVED: "Approuvée",
  STATUS_WAITING: "En attente",
  STATUS_REJECTED: "Rejetée",
  ACCEPT_THIS_INVITATION: "Accepter cette Invitation?",
  APPROVE_THIS_INVITATION: "Approver cette Invitation?",
  VIEW_INVITATION: "Invitation",
};
