<template>
  <div class="elite-content_sidebar_level1_menu_wrapper">
    <ul>
      <li>
        <router-link
          :to="{ name: 'Dashboard' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <img src="../../../../assets/img/menu-icon01.svg" alt="icon" />
            </span>
            <span class="text">
              {{ $t("APPS.DASHBOARD") }}
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_CRM)"
          :to="{ name: 'Crm Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <img src="../../../../assets/img/menu-icon02.svg" alt="icon" />
            </span>
            <span class="text">
              {{ $t("APPS.CRM_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <crm-module-menu></crm-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_SALES)"
          :to="{ name: 'Sales Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <img src="../../../../assets/img/menu-icon03.svg" alt="icon" />
            </span>
            <span class="text">
              {{ $t("APPS.SALES_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <sales-module-menu class="sub-menu"></sales-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_PURCHASES)"
          :to="{ name: 'Purchases Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <img src="../../../../assets/img/menu-icon04.svg" alt="icon" />
            </span>
            <span class="text">
              {{ $t("APPS.PURCHASES_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <purchases-module-menu class="sub-menu"></purchases-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_INVENTORY)"
          :to="{ name: 'Inventory Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <img src="../../../../assets/img/menu-icon05.svg" alt="icon" />
            </span>
            <span class="text">
              {{ $t("APPS.INVENTORY_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <inventory-module-menu class="sub-menu"></inventory-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_AUCTION)"
          :to="{ name: 'Auctions Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <img src="../../../../assets/img/menu-icon06.svg" alt="icon" />
            </span>
            <span class="text">
              {{ $t("APPS.AUCTION_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <auctions-module-menu class="sub-menu"></auctions-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_FILE_EXPLORER)"
          :to="{ name: 'Files Explorer' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <img src="../../../../assets/img/menu-icon07.svg" alt="icon" />
            </span>
            <span class="text">
              {{ $t("APPS.FILE_EXPLORER_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <files-explorer-module-menu></files-explorer-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_RESELLER)"
          :to="{ name: 'Reseller Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <img src="../../../../assets/img/menu-icon08.svg" alt="icon" />
            </span>
            <span class="text">
              {{ $t("APPS.RESELLER_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <reseller-module-menu></reseller-module-menu>
            </span>
          </a>
        </router-link>
      </li>
      <li>
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
          :to="{ name: 'Admin Page' }"
          v-slot="{ href, navigate, isActive }"
          custom
        >
          <a
            :href="href"
            @click="navigate"
            :class="{ 'active-link': isActive }"
          >
            <span class="icon">
              <img src="../../../../assets/img/menu-icon09.svg" alt="icon" />
            </span>
            <span class="text">
              {{ $t("APPS.ADMIN_MODULE") }}
            </span>
            <span class="sub-menu-wrapper">
              <admin-module-menu></admin-module-menu>
            </span>
          </a>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import AdminModuleMenu from "../../../Pages/AdminModule/AdminModuleMenu.vue";
import CrmModuleMenu from "../../../Pages/CrmModule/CrmModuleMenu.vue";
import FilesExplorerModuleMenu from "../../../Pages/FilesExplorerModule/FilesExplorerModuleMenu.vue";
import InventoryModuleMenu from "../../../Pages/InventoryModule/InventoryModuleMenu.vue";
import PurchasesModuleMenu from "../../../Pages/PurchasesModule/PurchasesModuleMenu.vue";
import ResellerModuleMenu from "../../../Pages/ResellerModule/ResellerModuleMenu.vue";
import SalesModuleMenu from "../../../Pages/SalesModule/SalesModuleMenu.vue";
import AuctionsModuleMenu from "../../../Pages/AuctionsModule/AuctionsModuleMenu";

export default {
  components: {
    AdminModuleMenu,
    ResellerModuleMenu,
    FilesExplorerModuleMenu,
    InventoryModuleMenu,
    SalesModuleMenu,
    PurchasesModuleMenu,
    CrmModuleMenu,
    AuctionsModuleMenu,
  },

  props: {},

  async created() {},

  computed: {},

  data() {
    return {};
  },

  methods: {},
};
</script>
