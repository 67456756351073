export const STATUS_DRAFT = "DRAFT";
export const STATUS_PENDING = "PENDING"
export const STATUS_COLLECTES = "COLLECTES";
export const STATUS_DELIVERED = "DELIVERED";
export const STATUS_CANCELED = "CANCELED";

export const COLLECTES_STATUS = [
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_COLLECTES,
  STATUS_DELIVERED,
  STATUS_CANCELED,
];