import common from "./common";
import apps from "./apps";
import errors from "./errors";
import organizations from "./organizations";
import organizationsgroups from "./organizationsgroups";
import resellers from "./resellers";
import users from "./users";
import roles from "./roles";
import permissions from "./permissions";
import products from "./products";
import resellerInvoices from "./resellerInvoices";
import resellerPayments from "./resellerPayments";
import resellerProduct from "./resellerProduct";
import resellerServices from "./resellerServices";
import invoices from "./invoices";
import customers from "./customers";
import packages from "./packages";
import subscriptions from "./subscriptions";
import payments from "./payments";
import profile from "./profile";
import contacts from "./contacts";
import labels from "./labels";
import charges from "./charges";
import chargePackages from "./chargePackages";
import suppliers from "./suppliers";
import locations from "./locations";
import files from "./files";
import notifications from "./notifications";
import purchasesOrders from "./purchasesOrders";
import purchasesInvoices from "./purchasesInvoices";
import purchasesDeliveries from "./purchasesDeliveries";
import purchasesPayments from "./purchasesPayments";
import salesOrders from "./salesOrders";
import salesInvoices from "./salesInvoices";
import salesDeliveries from "./salesDeliveries";
import salesPayments from "./salesPayments";
import warehouses from "./warehouses";
import stockMovements from "./stockMovements";
import imports from "./imports";
import properties from "./properties";
import propertyTransactions from "./propertyTransactions";
import auctions from "./auctions";
import invitations from "./invitations";
import events from "./events";
import vehicles from "./vehicles";
import wallet from "./wallet";
import transmissions from "@/i18n/fr/transmissions";
import fuelTypes from "@/i18n/fr/fuelTypes";
import transactions from "@/i18n/fr/transactions";
import collectes from "@/i18n/fr/collectes";
import bids from "@/i18n/fr/bids";

export default {
  COMMON: common,
  APPS: apps,
  ERRORS: errors,
  USERS: users,
  ORGANIZATIONS: organizations,
  ORGANIZATIONSGROUPS: organizationsgroups,
  RESELLERS: resellers,
  ROLES: roles,
  PERMISSIONS: permissions,
  PRODUCTS: products,
  INVOICES: invoices,
  CUSTOMERS: customers,
  SUBSCRIPTIONS: subscriptions,
  PACKAGES: packages,
  PAYMENTS: payments,
  PROFILE: profile,
  CONTACTS: contacts,
  LABELS: labels,
  CHARGES: charges,
  CHARGE_PACKAGES: chargePackages,
  SUPPLIERS: suppliers,
  LOCATIONS: locations,
  FILES: files,
  NOTIFICATIONS: notifications,
  PURCHASES_ORDERS: purchasesOrders,
  PURCHASES_INVOICES: purchasesInvoices,
  PURCHASES_DELIVERIES: purchasesDeliveries,
  PURCHASES_PAYMENTS: purchasesPayments,
  SALES_ORDERS: salesOrders,
  SALES_INVOICES: salesInvoices,
  SALES_DELIVERIES: salesDeliveries,
  SALES_PAYMENTS: salesPayments,
  RESELLER_PRODUCTS: resellerProduct,
  RESELLER_SERVICES: resellerServices,
  RESELLER_INVOICES: resellerInvoices,
  RESELLER_PAYMENTS: resellerPayments,
  WAREHOUSES: warehouses,
  STOCK_MOVEMENTS: stockMovements,
  IMPORTS: imports,
  PROPERTIES: properties,
  VEHICLES: vehicles,
  AUCTIONS: auctions,
  PROPERTY_TRANSACTIONS: propertyTransactions,
  INVITATIONS: invitations,
  EVENTS: events,
  WALLET: wallet,
  TRANSMISSIONS: transmissions,
  FUEL_TYPES: fuelTypes,
  TRANSACTIONS: transactions,
  COLLECTES: collectes,
  BIDS: bids,
};
