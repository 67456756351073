import service from "@/store/services/purchases-payments-service";

export const state = {
  list: {},
  purchasesPayment: {},
  meta: {},
};

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, purchasesPayment) => {
    state.purchasesPayment = purchasesPayment;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((purchasesPayment) => {
      commit("SET_RESOURCE", purchasesPayment);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((purchasesPayment) => {
      commit("SET_RESOURCE", purchasesPayment);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((purchasesPayment) => {
      commit("SET_RESOURCE", purchasesPayment);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
  validate({ commit, dispatch }, params) {
    return service.validate(params, this.$axios).then((purchasesPayment) => {
      commit("SET_RESOURCE", purchasesPayment);
    });
  },
  cancel({ commit, dispatch }, params) {
    return service.cancel(params, this.$axios).then((purchasesPayment) => {
      commit("SET_RESOURCE", purchasesPayment);
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  purchasesPayment: (state) => state.purchasesPayment,
};

const purchasesPayments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default purchasesPayments;
