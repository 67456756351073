export default {
  LOCATIONS_LIST: "Liste des concessions",
  ADD_LOCATION: "Ajouter une concession",
  LOCATION_ADDED: "Concession ajoutée",
  LOCATIONS_UPDATED: "Concession modifiée",
  EDIT_LOCATION: "Modifier cette concession",
  DELETE_THIS_LOCATION: "Supprimer cette concession ?",
  LOCATION_DELETED: "Concession supprimée",
  VIEW_LOCATION: "Concession",
  IS_MUNICIPAL: "Est une municipalité",
};
